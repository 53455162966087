import React from 'react'
import classnames from 'classnames/bind'
import styles from './button.module.scss'
const cx = classnames.bind(styles)

const Button = ({
  children,
  danger,
  disabled,
  success,
  small,
  outline,
  onClick,
}) => {
  const style = cx({
    button: !outline,
    outline,
    danger,
    success,
    disabled,
    small,
  })
  return (
    <button onClick={onClick} className={style}>
      {children}
    </button>
  )
}

export default Button
