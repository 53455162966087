/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import cn from './layout.module.scss'

import '../styles/root.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query HeroQuery {
        file(relativePath: { eq: "bg.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = data
  return (
    <BackgroundImage
      tag="section"
      fluid={fluid}
      backgroundColor={`#040e18`}
      className={cn.bg}
    >
      <div className={cn.overlay}>{children}</div>
    </BackgroundImage>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
